import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FB_DEFAULT_APIKEY,
  projectId: process.env.VUE_APP_FB_DEFAULT_PROJECTID,
});

firebase.auth().languageCode = 'ja';

export default firebase;