export default {
  methods: {
    gaExceptionId() {
      const count = 6;
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let autoId = '';
      for (let i = 0; i < count; i += 1) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return autoId;
    },
    gaException({ id, file, location, message }) {
      if (this.$mode === 'development') return;
      const exception = `id:${id}, file:${file}, location:${location}, message:${message}, userAgent:${
        window.navigator.userAgent
      }, date:${new Date()}`;
      this.$ga.exception(exception);
    },
  },
};
