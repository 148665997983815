<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex xs12 sm8 md6 xl4>
        <transition name="fade">
          <v-container v-if="step===1">
            <v-layout row wrap>
              <v-flex xs12 class="text-xs-center mb-4">
                <h2 class="headline">HoteKanアカウントにようこそ！</h2>
                <div class="subheading">account.hotekan.com</div>
              </v-flex>
              <v-flex xs12 class="text-xs-center mb-4">
                <div class="subheading">1つのHoteKanアカウントでHoteKan設備やHoteKan備品など、すべてのHoteKanサービスにサインインできます！</div>
              </v-flex>
              <v-flex xs12 class="text-xs-center mb-2">
                <div class="subheading">パスワードを設定してください</div>
              </v-flex>
              <v-flex xs12 class="mb-2">
                <v-alert :value="!!error" type="error">{{error}}</v-alert>
              </v-flex>
              <v-flex xs12>
                <v-form ref="passwordForm" @submit.prevent="signup">
                  <v-text-field
                    v-model.trim="password"
                    :rules="rules.password"
                    :append-icon="visibile?'mdi-eye-off' : 'mdi-eye'"
                    :type="visibile ? 'text' : 'password'"
                    @click:append="visibile = !visibile"
                    single-line
                    color="accent"
                    outline
                    :disabled="disabled"
                  ></v-text-field>
                  <v-btn large block color="accent" class="mb-3" @click="signup" :disabled="disabled">パスワードを登録
                    <v-icon small class="ml-2">mdi-send</v-icon>
                  </v-btn>
                </v-form>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container v-else-if="step===2">
            <v-layout row wrap>
              <v-flex xs12 class="text-xs-center mb-4">
                <v-progress-circular :size="100" :width="7" color="accent" indeterminate></v-progress-circular>
              </v-flex>
              <v-flex xs12 class="text-xs-center mb-4">
                <h2 class="headline mb-4">処理中です！</h2>
                <p class="body-2">処理が完了するまで、もうしばらくお待ちください。</p>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container v-else-if="step===3">
            <v-layout row wrap>
              <v-flex xs12 class="text-xs-center mb-2">
                <v-icon size="100">mdi-account-circle</v-icon>
              </v-flex>
              <v-flex xs12 class="text-xs-center mb-4">
                <h2 class="headline mb-4">ようこそHoteKanへ！</h2>
                <p class="body-2">お疲れ様です。アカウントの登録が完了しました。</p>
              </v-flex>
              <v-flex v-if="url" xs12 class="text-xs-center mb-2">
                <v-btn large color="accent" class="mb-3" :href="url" :disabled="disabled">HoteKanにサインインする
                  <v-icon small class="ml-2">mdi-open-in-new</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from '@/vendors/firebase';
import Analytics from '@/mixins/analytics';

export default {
  mixins: [Analytics],
  data() {
    return {
      oobCode: null,
      url: null,
      step: 1,
      error: null,
      disabled: false,
      password: '',
      visibile: false,
      rules: {
        password: [
          v => !!v || '必須です',
          v => (v && v.length >= 10) || '10文字以上にして下さい',
          v => (v && v.length <= 128) || '128文字以下にして下さい'
        ]
      }
    };
  },
  created() {
    const { mode, oobCode, url } = this.$route.query;
    if (mode !== 'registerUser' || !oobCode) this.$router.push({ name: '400' });
    this.oobCode = oobCode;
    this.url = url;
  },
  methods: {
    signup() {
      this.error = null;
      // validate
      if (!this.$refs.passwordForm.validate()) {
        this.error = '入力内容を確認してください。';
        return null;
      }
      // ui
      this.step = 2;
      this.disabled = true;
      // signup
      const { oobCode, password } = this;
      const signup = firebase.functions().httpsCallable('signup');
      return signup({ oobCode, password })
        .then(result =>
          firebase
            .auth()
            .signInWithCustomToken(result.data.token)
            .then(() => {
              this.disabled = false;
              this.step = 3;
            })
        )
        .catch(error => {
          if (error.code === 'not-found' && error.message === 'not-found-oobCode') {
            this.error = 'リンクの期限が切れたか、リンクがすでに使用されています。';
          } else {
            const id = this.gaExceptionId();
            this.error = `問題が発生しました。再試行しても解決しない場合、下記のエラーIDを記載してサポートまでご連絡ください（エラーID: ${id}）`;
            this.gaException({ id, file: 'Signin.vue', location: 'signin', message: error.message });
          }
          this.disabled = false;
          this.step = 1;
        });
    }
  }
};
</script>
